import { EventEmitter, Injectable } from '@angular/core';
import { InteractionService } from "./interaction.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root',
})

export class AvatarAudioService {
    private audioObj = new Audio();
    public onAudioLoaded = new EventEmitter<boolean>();
    public isAudioPlaying = new BehaviorSubject<boolean>(false);

    constructor(private interaction: InteractionService) {
        // Bind the event handlers to the class instance
        this.audioLoadedHandler = this.audioLoadedHandler.bind(this);
        this.audioErrorHandler = this.audioErrorHandler.bind(this);
    }

    getAudioObj() {
        return this.audioObj;
    }

    setAudioObj(audioObj: any) {
        this.audioObj = audioObj;
    }

    private audioLoadedHandler() {
        this.onAudioLoaded.emit(true); // Notify that audio is loaded and ready
        this.audioObj.play().then();

        var readyState = this.audioObj.readyState;
        console.log(readyState)

        // Remove listeners after they have been invoked
        this.removeEventListeners();
    }

    private audioErrorHandler() {
        this.onAudioLoaded.emit(false); // Notify if there was an error loading the audio
        this.removeEventListeners();
    }

    private addEventListeners() {
        this.audioObj.addEventListener('canplaythrough', this.audioLoadedHandler);
        this.audioObj.addEventListener('error', this.audioErrorHandler);
    }

    private removeEventListeners() {
        //this.audioObj.removeEventListener('canplaythrough', this.audioLoadedHandler);
        this.audioObj.removeEventListener('error', this.audioErrorHandler);
    }

    playAudio(url: string) {
        this.audioObj.src = url;
        this.audioObj.load();
        this.audioObj.autoplay = true;

        this.audioObj.onplay = () => {
            this.isAudioPlaying.next(true);
            this.setAudioObj(this.audioObj);
            this.onAudioLoaded.emit(true);
            this.removeEventListeners();
        };

        this.audioObj.onended = () => {
            this.isAudioPlaying.next(false);
            this.stop()
        }
    }

    stop() {
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
    }
}
